import { TextareaAutosize, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field } from 'formik';
import {
  InclusionLawEnum,
  LinkTypeEnum,
  PaymentTypeEnum,
  UserTypeEnum,
  ValidUntil,
} from '../../store/config/enums';
import { AuthState, LinkValues, RootState, SplitLinkRequestValues } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { FormDateField } from '../forms/FormDateField';
import { FormSelectField } from '../forms/FormSelectField';
import { FormTextField } from '../forms/FormTextField';
import options from './inputData';
import AmountInput from './RegularLink/AmountInput';
import VATtooltip from './VATTooltip';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      '& p': {
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    informationRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.9375rem',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    textarea: {
      padding: '0.5625rem 1.5625rem 0.5625rem 0.625rem',
      margin: '1rem 0',
      color: styles.slateBlue,
      border: `1px solid ${styles.borderButtonSecondary}`,
      borderRadius: '4px',
      fontFamily: 'Rubik',
      fontSize: '1rem',
      '&::placeholder': {
        color: styles.slateBlue,
      },
      resize: 'none',
      '&:focus': {
        border: '1px solid transparent',
        outline: `${styles.primary} solid 2px`,
      },
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.8rem 0 -0.6rem 0 ',
      '& span': {
        display: 'flex',
        marginLeft: '0.375rem',
      },
      '& p': {
        margin: 0,
      },
    },
    informationLabel: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& i': {
        fontSize: '0.875rem',
        margin: '0.8rem 0 -0.6rem 0 ',
        color: styles.almostGrey,
        textAlign: 'right',
      },
    },
  }),
);

interface InputsProps {
  values: LinkValues | SplitLinkRequestValues;
  linkType: LinkTypeEnum;
  paymentType?: PaymentTypeEnum;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

function Inputs({ values, linkType, paymentType, setFieldValue }: InputsProps) {
  const classes = useStyles();
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const isRegularPayment = paymentType === PaymentTypeEnum.Regular;
  const regularPaymentValues = values as LinkValues;
  const law17934Selected = values.inclusionLaw === InclusionLawEnum.LAW17934;
  const collaboratorCanUpdateLaw = auth.account?.business?.collaboratorCanUpdateLaw;
  const isCollaborator = auth.account?.type === UserTypeEnum.COLLABORATOR;

  return (
    <>
      <div className={classes.informationRow}>
        <div className={classes.informationCol}>
          <p>Moneda</p>
          <Field name="currency" component={FormSelectField} type="text" options={options.currency} />
        </div>
        <div className={classes.informationCol}>
          {isRegularPayment ? (
            <AmountInput values={regularPaymentValues} informationLabelClass={classes.informationLabel} />
          ) : (
            <>
              <div className={classes.informationCol}>
                <div className={classes.tooltipContainer}>
                  <p>Ley de Inclusión Financiera</p>
                  <VATtooltip title="La opción seleccionada se aplicará únicamente para este link de pago." />
                </div>
              </div>
              <Field
                name="inclusionLaw"
                component={FormSelectField}
                type="text"
                options={options.inclusionLaw}
                disabled={!collaboratorCanUpdateLaw && isCollaborator}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedValue: number = Number(event.target.value);
                  setFieldValue('inclusionLaw', selectedValue);
                  const newVatRateOptions =
                    selectedValue === InclusionLawEnum.LAW17934 ? options.vatRateFor17934 : options.vatRate;
                  const currentVatRate = values.vatRate;
                  const isCurrentOptionValid = newVatRateOptions.some(
                    (option) => option.id === currentVatRate,
                  );
                  const newSubBusinessVatRateOptions =
                    selectedValue === InclusionLawEnum.LAW17934 ? options.vatRateFor17934 : options.vatRate;
                  const updatedVatRate = isCurrentOptionValid ? currentVatRate : newVatRateOptions[0].id;
                  const currentSubbusinessVatRate = (values as SplitLinkRequestValues).subBusinessVatRate;
                  const isCurrentSubbusinessOptionValid = newSubBusinessVatRateOptions.some(
                    (option) => option.id === currentSubbusinessVatRate,
                  );
                  const updatedSubBusinessVatRate = isCurrentSubbusinessOptionValid
                    ? currentSubbusinessVatRate
                    : newSubBusinessVatRateOptions[0].id;
                  setFieldValue('vatRate', updatedVatRate);
                  setFieldValue('subBusinessVatRate', updatedSubBusinessVatRate);
                }}
              />
            </>
          )}
        </div>
        {!isRegularPayment && linkType === LinkTypeEnum.PERMANENT && (
          <div className={classes.informationCol}>
            <p>Vencimiento</p>
            <Field name="validUntil" component={FormSelectField} type="text" options={options.validUntil} />
            {values.validUntil === ValidUntil.CUSTOM && (
              <Field
                label="validUntilDate"
                name="validUntilDate"
                component={FormDateField}
                placeholder="Fecha vencimiento"
                type="text"
              />
            )}
          </div>
        )}
      </div>
      <div className={classes.informationRow}>
        {isRegularPayment && (
          <div className={classes.informationCol}>
            <div className={classes.tooltipContainer}>
              <p>IVA</p>
              <VATtooltip title="El IVA seleccionado se aplicará únicamente para este link de pago." />
            </div>
            <Field
              name="vatRate"
              component={FormSelectField}
              type="text"
              options={law17934Selected ? options.vatRateFor17934 : options.vatRate}
            />
          </div>
        )}
        {isRegularPayment && linkType === LinkTypeEnum.PERMANENT && (
          <div className={classes.informationCol}>
            <div className={classes.tooltipContainer}>
              <p>Ley de Inclusión Financiera</p>
              <VATtooltip title="La opción seleccionada se aplicará únicamente para este link de pago." />
            </div>
            <Field
              name="inclusionLaw"
              component={FormSelectField}
              type="text"
              options={options.inclusionLaw}
              disabled={!collaboratorCanUpdateLaw && isCollaborator}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedValue: number = Number(event.target.value);
                setFieldValue('inclusionLaw', selectedValue);
                const newVatRateOptions =
                  selectedValue === InclusionLawEnum.LAW17934 ? options.vatRateFor17934 : options.vatRate;
                const currentVatRate = values.vatRate;
                const isCurrentOptionValid = newVatRateOptions.some((option) => option.id === currentVatRate);
                const updatedVatRate = isCurrentOptionValid ? currentVatRate : newVatRateOptions[0].id;
                setFieldValue('vatRate', updatedVatRate);
              }}
            />
          </div>
        )}
        {isRegularPayment && linkType === LinkTypeEnum.ONETIME ? (
          <div className={classes.informationRow}>
            <div className={classes.informationCol}>
              <div className={classes.tooltipContainer}>
                <p>Ley de Inclusión Financiera</p>
                <VATtooltip title="La opción seleccionada se aplicará únicamente para este link de pago." />
              </div>
              <Field
                name="inclusionLaw"
                component={FormSelectField}
                type="text"
                options={options.inclusionLaw}
                disabled={!collaboratorCanUpdateLaw && isCollaborator}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedValue: number = Number(event.target.value);
                  setFieldValue('inclusionLaw', selectedValue);
                  const newVatRateOptions =
                    selectedValue === InclusionLawEnum.LAW17934 ? options.vatRateFor17934 : options.vatRate;
                  const currentVatRate = values.vatRate;
                  const isCurrentOptionValid = newVatRateOptions.some(
                    (option) => option.id === currentVatRate,
                  );
                  const updatedVatRate = isCurrentOptionValid ? currentVatRate : newVatRateOptions[0].id;
                  setFieldValue('vatRate', updatedVatRate);
                }}
              />
            </div>
          </div>
        ) : (
          isRegularPayment &&
          linkType === LinkTypeEnum.PERMANENT && (
            <div className={classes.informationCol}>
              <p>Vencimiento</p>
              <Field name="validUntil" component={FormSelectField} type="text" options={options.validUntil} />
              {values.validUntil === ValidUntil.CUSTOM && (
                <Field
                  label="validUntilDate"
                  name="validUntilDate"
                  component={FormDateField}
                  placeholder="Fecha vencimiento"
                  type="text"
                />
              )}
            </div>
          )
        )}
      </div>
      <div className={classes.informationRow}>
        {isRegularPayment && linkType === LinkTypeEnum.ONETIME && (
          <div className={classes.informationCol}>
            <p>Vencimiento</p>
            <Field name="validUntil" component={FormSelectField} type="text" options={options.validUntil} />
            {values.validUntil === ValidUntil.CUSTOM && (
              <Field
                label="validUntilDate"
                name="validUntilDate"
                component={FormDateField}
                placeholder="Fecha vencimiento"
                type="text"
              />
            )}
          </div>
        )}
        {linkType === LinkTypeEnum.ONETIME && !isRegularPayment && (
          <>
            <div className={classes.informationCol}>
              <p>Vencimiento</p>
              <Field name="validUntil" component={FormSelectField} type="text" options={options.validUntil} />
              {values.validUntil === ValidUntil.CUSTOM && (
                <Field
                  label="validUntilDate"
                  name="validUntilDate"
                  component={FormDateField}
                  placeholder="Fecha vencimiento"
                  type="text"
                />
              )}
            </div>
            <div className={classes.informationCol}>
              <div className={classes.informationLabel}>
                <p>Referencia o destinatario (Opcional)</p>
              </div>
              <Field className="form-row" name="reference" component={FormTextField} type="text" />
            </div>
          </>
        )}
      </div>
      <div className={classes.informationRow}>
        <div className={classes.informationCol}>
          <div className={classes.informationLabel}>
            <p>Descripción del producto o servicio (Opcional)</p>
            <i>Restan {50 - values.description?.length!} caracteres</i>
          </div>
          <Field
            className="form-row"
            name="description"
            render={({ field }: any) => (
              <TextareaAutosize
                contentEditable={false}
                {...field}
                className={classes.textarea}
                rowsMin={3}
                defaultValue=""
                maxLength={50}
              />
            )}
            type="text"
          />
        </div>
      </div>
    </>
  );
}

export default Inputs;
