import BankIcon from '../assets/BankIcon';

const bankLogoMapping: Record<string, string> = {
  AMEX: '/bank-logos/amex.svg',
  ANDA: '/bank-logos/anda.svg',
  BBVA: '/bank-logos/bbva.svg',
  BROU: '/bank-logos/brou.svg',
  CABAL: '/bank-logos/cabal.svg',
  CREDITEL: '/bank-logos/creditel.svg',
  FUCAC: '/bank-logos/fucac.svg',
  HSBC: '/bank-logos/hsbc.svg',
  ITAU: '/bank-logos/itau.svg',
  MIDINERO: '/bank-logos/mi-dinero.svg',
  OCA: '/bank-logos/oca.svg',
  PASSCARD: '/bank-logos/pass-card.svg',
  PIX: '/bank-logos/pix.svg',
  PREX: '/bank-logos/prex.svg',
  SANTANDER: '/bank-logos/santander.svg',
  SCOTIABANK: '/bank-logos/scotiabank.svg',
  SISTARBANC: '/bank-logos/sistarbanc.svg',
  TARJETAD: '/bank-logos/tarjeta-d.svg',
};

const capitalizeIssuerName = (issuer: string): string => {
  const words = issuer
    .split(' ')
    .map((word) => (word.match(/^(SA|S\.A\.)$/i) ? word.toUpperCase() : word.toLowerCase()));

  return words
    .map((word) => (word === 'SA' || word === 'S.A.' ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join(' ');
};

const normalizeIssuer = (issuer: string): string => {
  const trimmedIssuer = issuer.trim().toUpperCase();

  switch (trimmedIssuer) {
    case 'BANCO REPUBLICA ORIENTAL DEL URUGUAY':
    case 'BROU':
      return 'BROU';
    case 'FUCAC VERDE COOPERATIVA DE AHORRO Y CREDITO':
      return 'Fucac';
    case 'UNKNOWN':
    default:
      return trimmedIssuer;
  }
};

const getBankLogoAndImage = (issuer: string | null, onlyImage: boolean = false): JSX.Element => {
  if (!issuer || issuer.toUpperCase() === 'UNKNOWN') {
    return (
      <>
        <BankIcon />
        {!onlyImage && <span>Banco no reportado</span>}
      </>
    );
  }

  const normalizedIssuer = normalizeIssuer(issuer);
  const logoSrc = bankLogoMapping[normalizedIssuer];
  const capitalizedIssuer = <span>{capitalizeIssuerName(issuer)}</span>;

  if (logoSrc) {
    return (
      <>
        <img src={logoSrc} alt={issuer} style={{ maxHeight: '20px', maxWidth: '60px' }} />
        {!onlyImage && capitalizedIssuer}
      </>
    );
  }

  return (
    <>
      <BankIcon />
      {!onlyImage && capitalizedIssuer}
    </>
  );
};

export const issuerCardHelper = {
  bankLogoMapping,
  getBankLogoAndImage,
};
