import { Paper, Theme, createStyles, makeStyles, useMediaQuery, useTheme, Divider } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dummy from '../../assets/dummy.png';
import { CustomSnackbar } from '../../components/CustomSnackbar';
import { AlertTriangle } from '../../components/Icons/AlertTriangle';
import { ImageUpload } from '../../components/ImageUpload';
import VATTooltip from '../../components/Link/VATTooltip';
import options from '../../components/Link/inputData';
import { Loading } from '../../components/Loading';
import { PaymentMethods } from '../../components/PaymentMethods';
import { ScreenTitle } from '../../components/ScreenTitle';
import { SubmitButton } from '../../components/SubmitButton';
import { Warning } from '../../components/Warning';
import { FormSelectField } from '../../components/forms/FormSelectField';
import { FormTextField } from '../../components/forms/FormTextField';
import schemas from '../../data/schemas';
import { businessService } from '../../services/business.service';
import { updateBusiness } from '../../store/action_creators/business.actions';
import { RootState, UpdateBusinessRequest, UpdateImageRequest } from '../../store/config/types';
import { InclusionLawEnum } from '../../store/config/enums';
import { CustomSwitch } from '../../components/CustomSwitch';
import styles from '../../styles/_variables.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    informationCol: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
      '& p': {
        margin: '0.8rem 0 -0.6rem 0 ',
      },
    },
    informationRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.9375rem',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    colaboratorLaw: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '0.9375rem',
      flexWrap: 'nowrap',
      width: '100%',
    },
    colaboratorLawTitle: {
      margin: 0,
      fontSize: '0.875rem',
      fontWeight: +styles.semiBoldTextWeight,
      color: styles.slateBlue,
    },
    colaboratorLawMessage: {
      margin: '0.3125rem 0 0 0 ',
      fontSize: '0.875rem',
      fontWeight: +styles.normalTextWeight,
      color: styles.iconColor,
    },
    divider: {
      color: styles.slateBlue,
      height: '0.0625rem',
      margin: '1rem 0',
    },
    tooltipContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0.8rem 0 -0.6rem 0 ',
      '& span': {
        display: 'flex',
        marginLeft: '0.375rem',
      },
      '& p': {
        margin: 0,
      },
    },
  }),
);
interface Values {
  prettyName: string;
  urlName: string;
  plexoCommerceName: string;
  legalId: string;
  description: string;
  vat: number;
  defaultInclusionLaw: InclusionLawEnum;
}

function Business() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { auth, business } = useSelector((state: RootState) => state);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [pictureError, setPictureError] = useState<string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);
  const [collaboratorCanUpdateLawEnabled, setCollaboratorCanUpdateLawEnabled] = useState<boolean>(false);
  const imageRef = useRef<HTMLImageElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (imageRef.current && imageRef.current.src === '') {
      imageRef.current.src = dummy;
    }
  });

  useEffect(() => {
    if (!initialized && auth.account && imageRef.current !== null) {
      setInitialized(true);
      setCollaboratorCanUpdateLawEnabled(auth.account.business.collaboratorCanUpdateLaw ?? false);
      if (auth.account.business.logoImage) {
        imageRef.current.src = `data:${auth.account.business.logoImage.contentType};base64,${auth.account.business.logoImage.data}`;
      }
    }
  }, [initialized, auth.account]);

  const submitBusiness = (values: Values, helpers: FormikHelpers<Values>) => {
    if (auth.account) {
      setUpdating(true);
      setPictureError(null);

      const updatedBusiness: UpdateBusinessRequest = {
        businessId: auth.account.business.id!,
        urlName: values.urlName,
        prettyName: values.prettyName,
        plexoCommerceName: auth.account.business.plexoCommerceName,
        legalId: values.legalId,
        acceptsOnTheFly: auth.account.business.acceptsOnTheFly!,
        softDescriptor: values.description,
        vatRate: values.vat!,
        sendNotification: auth.account.business.mailNotificationsEnabled!,
        mailForNotifications: auth.account.business.mailForNotifications,
        urlForSystemNotifications: auth.account.business.urlForSystemNotifications,
        clientId: auth.account.business.clientId,
        clientSecret: auth.account.business.clientSecret,
        acceptsSplitPayments: auth.account.business.acceptsSplitPayments!,
        defaultInclusionLaw: values.defaultInclusionLaw,
        collaboratorCanUpdateLaw: collaboratorCanUpdateLawEnabled,
      };

      dispatch(updateBusiness(updatedBusiness));

      if (selectedPhoto) {
        const updateImageRequest: UpdateImageRequest = {
          businessId: auth.account.business.id!,
          file: selectedPhoto!,
        };

        businessService.updateBusinessPicture(updateImageRequest).catch((error) => {
          setPictureError(error.message);
        });
      }
    }
  };

  const closeSnack = () => {
    setUpdating(false);
  };

  const handleCanCollaboratorUpdateLawChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCollaboratorCanUpdateLawEnabled(event.target.checked);
  };

  const submitButton = <SubmitButton formId="businessForm" loading={updating} disabled={!!imageError} />;

  return (
    <div className="screen-container configuration">
      <ScreenTitle title="Empresa" formId={!isMobile ? 'businessForm' : undefined} loading={updating} />
      {auth.account ? (
        <Formik
          initialValues={{
            prettyName: auth.account.business.prettyName,
            urlName: auth.account.business.urlName,
            plexoCommerceName: auth.account.business.plexoCommerceName,
            legalId: auth.account.business.legalId,
            description: auth.account.business.softDescriptor,
            vat: auth.account.business.vatRate ?? 0,
            defaultInclusionLaw: auth.account.business.defaultInclusionLaw ?? InclusionLawEnum.NONE,
          }}
          validationSchema={schemas.EditBusinessSchema}
          onSubmit={submitBusiness}
        >
          {({ values, setFieldValue }) => {
            const law17934Selected = values.defaultInclusionLaw === InclusionLawEnum.LAW17934;

            return (
              <Form id="businessForm" className="form">
                <Paper elevation={2} className="configuration-paper">
                  <ImageUpload
                    image={selectedPhoto}
                    setImage={setSelectedPhoto}
                    error={imageError}
                    setError={setImageError}
                    imageRef={imageRef}
                    isUpdating
                  />
                  <div className={classes.informationRow}>
                    <div className={classes.informationCol}>
                      <p>Nombre</p>
                      <Field name="prettyName" component={FormTextField} type="text" />
                    </div>
                    <div className={classes.informationCol}>
                      <p>Nombre para la dirección web</p>
                      <Field name="urlName" component={FormTextField} type="text" disabled />
                    </div>
                  </div>
                  <div className={classes.informationRow}>
                    <div className={classes.informationCol}>
                      <p>Nombre en Plexo</p>
                      <Field name="plexoCommerceName" component={FormTextField} type="text" disabled />
                    </div>
                    <div className={classes.informationCol}>
                      <p>RUT</p>
                      <Field name="legalId" component={FormTextField} type="text" disabled />
                    </div>
                  </div>
                </Paper>
                <Paper elevation={2} className="configuration-paper">
                  <h3 className="upload-title">Información del pago</h3>
                  <div className={classes.informationRow}>
                    <div className={classes.informationCol}>
                      <div className={classes.tooltipContainer}>
                        <p>IVA</p>
                        <VATTooltip title="Al modificar el IVA, los links previamente creados conservarán el IVA con el que fueron generados anteriormente." />
                      </div>
                      <Field
                        name="vat"
                        component={FormSelectField}
                        type="text"
                        options={law17934Selected ? options.vatRateFor17934 : options.vatRate}
                      />
                    </div>
                    <div className={classes.informationCol}>
                      <div className={classes.tooltipContainer}>
                        <p>Opción por defecto de Ley de Inclusión Financiera</p>
                        <VATTooltip title="La opción seleccionada será la utilizada para los pagos rápidos o al crear links mediante un archivo CSV." />
                      </div>
                      <Field
                        name="defaultInclusionLaw"
                        component={FormSelectField}
                        type="text"
                        options={options.inclusionLaw}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                          const selectedValue: number = Number(event.target.value);
                          setFieldValue('inclusionLaw', selectedValue);
                          const newVatRateOptions =
                            selectedValue === InclusionLawEnum.LAW17934
                              ? options.vatRateFor17934
                              : options.vatRate;
                          const currentVatRate = values.vat;
                          const isCurrentOptionValid = newVatRateOptions.some(
                            (option) => option.id === currentVatRate,
                          );
                          const updatedVatRate = isCurrentOptionValid
                            ? currentVatRate
                            : newVatRateOptions[0].id;
                          setFieldValue('vat', updatedVatRate);
                        }}
                      />
                    </div>
                  </div>
                  <Divider className={classes.divider} flexItem orientation="horizontal" />
                  <div className={classes.colaboratorLaw}>
                    <p className={classes.colaboratorLawTitle}>
                      Permitir a los colaboradores modificar la opción de ley de Inclusión Financiera
                    </p>
                    <CustomSwitch
                      checked={collaboratorCanUpdateLawEnabled}
                      onChange={handleCanCollaboratorUpdateLawChange}
                    />
                  </div>
                  <p className={classes.colaboratorLawMessage}>
                    Los colaboradores pueden modificar la opción de ley predeterminada configurada por la
                    empresa al crear nuevos links.
                  </p>
                  {auth.account?.business.vatRate !== values.vat && (
                    <Warning
                      text="Al modificar el IVA, los links previamente creados conservarán el IVA con el que fueron generados anteriormente."
                      icon={<AlertTriangle />}
                    />
                  )}
                </Paper>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <Loading />
      )}
      {isMobile && submitButton}
      <ScreenTitle title="Medios de Pago" />
      <PaymentMethods />
      <CustomSnackbar
        open={updating && (business.updateBusinessSuccess || business.updateBusinessErrorMessage !== null)}
        message={
          pictureError !== null
            ? pictureError
            : business.updateBusinessSuccess
            ? 'Se actualizó la empresa correctamente'
            : business.updateBusinessErrorMessage!
        }
        handleClose={closeSnack}
        type={!business.updateBusinessSuccess || pictureError !== null ? 1 : 0}
      />
    </div>
  );
}

export default Business;
