import { InclusionLawEnum } from '../store/config/enums';

export const inclusionLawHelper = {
  getInclusionLawName,
};

function getInclusionLawName(inclusionLaw: InclusionLawEnum): string | undefined {
  switch (inclusionLaw) {
    case InclusionLawEnum.LAW17934:
      return 'Ley 17934';
    case InclusionLawEnum.LAW19210:
      return 'Ley 19210';
    case InclusionLawEnum.NONE:
      return 'Ninguna';
    default:
      return undefined;
  }
}
