import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import image from '../../assets/logo-white.png';
import { currencyHelper } from '../../helpers/currencyHelper';
import { inclusionLawHelper } from '../../helpers/inclusionLawHelper';
import { numberHelper } from '../../helpers/numberHelper';
import { urlHelper } from '../../helpers/urlHelper';
import { InclusionLawEnum } from '../../store/config/enums';
import {
  ConfirmedPaymentPDFInfo,
  LinkDataPaymentPDFType,
  PaymentPDFType,
  SelectedPaymentPDFType,
} from '../../store/config/types';
import { theme } from '../../styles/theme';

type HTTPMethod = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

type SourceObject =
  | string
  | { data: Buffer; format: 'png' | 'jpg' }
  | { uri: string; method: HTTPMethod; body: any; headers: any };

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 15px',
  },
  imageContainer: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  footerContainer: {
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  image: {
    height: 60,
    width: 'auto',
  },
  content: {
    margin: '0',
    marginTop: '30px',
    width: '500px',
    height: '500px',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexGrow: 1,
    border: '1 solid #666',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomColor: '#FFF',
    flexDirection: 'column',
    padding: 15,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  separator: {
    margin: '15px',
    borderTop: '1 solid #666',
  },
  horizontalChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountChunk: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  verticalChunk: {
    display: 'flex',
    flexDirection: 'column',
  },
  verticalLastChunk: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  smallText: {
    fontSize: 12,
    color: '#666',
    marginTop: 4,
    marginBottom: 4,
  },
  date: {
    marginLeft: 7,
  },
  currency: {
    fontSize: 20,
    marginBottom: 2,
  },
  bigNumber: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 24,
  },
  issuerName: {
    marginRight: 6,
  },
  cardNumber: {
    marginLeft: 6,
  },
});

type PDFReceiptProps = {
  confirmedPayment: ConfirmedPaymentPDFInfo;
  businessVatRate?: number;
} & (SelectedPaymentPDFType | LinkDataPaymentPDFType | PaymentPDFType);

const isPaymentInstrument = (
  anyPayment: SelectedPaymentPDFType | LinkDataPaymentPDFType | PaymentPDFType,
): anyPayment is PaymentPDFType => anyPayment.type === 'payment';
const isSelectedPaymentInstrument = (
  anyPayment: SelectedPaymentPDFType | LinkDataPaymentPDFType | PaymentPDFType,
): anyPayment is SelectedPaymentPDFType => anyPayment.type === 'selectedPayment';

function PDFReceipt(props: PDFReceiptProps) {
  let issuerNamePDF: string;
  let cardNumber: string;
  let datePDF: Date;
  let descriptionPDF: string;

  if (isPaymentInstrument(props)) {
    const { issuerName, maskedPan, date, description } = props;
    issuerNamePDF = issuerName;
    cardNumber = maskedPan;
    datePDF = date;
    descriptionPDF = description;
  } else if (isSelectedPaymentInstrument(props)) {
    const { instrument, createdAt, linkDescription } = props;
    issuerNamePDF = instrument.issuerName;
    datePDF = createdAt;
    descriptionPDF = linkDescription;
    cardNumber = instrument.name;
  } else {
    const { description, created, instrument } = props;
    issuerNamePDF = instrument.issuer.name;
    datePDF = created;
    descriptionPDF = description;
    cardNumber = instrument.name;
  }

  const { businessId, businessVatRate, confirmedPayment, inclusionLaw } = props;

  const urlObject: SourceObject = {
    uri: urlHelper.buildImageUrl(businessId),
    method: 'GET',
    body: '',
    headers: {},
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <View style={styles.imageContainer}>
            <Image style={styles.image} source={urlObject} />
          </View>
          <View style={styles.content}>
            <View style={styles.details}>
              <View style={styles.line}>
                <Text>{'Pago realizado'}</Text>
                <Text>{props.confirmedPayment.plexoReferenceId}</Text>
              </View>
              <View style={styles.separator} />
              <View style={styles.line}>
                <View style={styles.horizontalChunk}>
                  {issuerNamePDF && <Text style={styles.issuerName}>{issuerNamePDF}</Text>}
                  <Text style={styles.smallText}>terminada en</Text>
                  <Text style={styles.cardNumber}>{` ${cardNumber.substring(cardNumber.length - 4)}`}</Text>
                </View>
                <View style={styles.horizontalChunk}>
                  <Text style={styles.smallText}>Fecha</Text>
                  <Text style={styles.date}>{moment(datePDF).format('DD/MM/YYYY')}</Text>
                </View>
              </View>
              <View style={styles.separator} />
              <View style={styles.line}>
                <View style={styles.verticalChunk}>
                  <Text style={styles.smallText}>Moneda</Text>
                  <Text>{currencyHelper.getCurrencyName(confirmedPayment.currency)}</Text>
                </View>
                <View style={styles.verticalLastChunk}>
                  <View style={styles.amountChunk}>
                    <Text style={styles.currency}>
                      {currencyHelper.getCurrencyToken(confirmedPayment.currency)}
                    </Text>
                    <Text style={styles.bigNumber}>
                      {numberHelper.getNumberWithDots(confirmedPayment.amountValue)}
                    </Text>
                    <Text style={styles.currency}>
                      {businessVatRate && businessVatRate > 0 ? 'IVA inc.' : ''}
                    </Text>
                  </View>
                  <Text style={styles.smallText}>
                    {`(${confirmedPayment.selectedInstallment} cuota${
                      confirmedPayment.selectedInstallment > 1 ? 's' : ''
                    })`}
                  </Text>
                  {inclusionLaw !== InclusionLawEnum.NONE && (
                    <Text style={styles.smallText}>
                      {inclusionLawHelper.getInclusionLawName(inclusionLaw)}
                    </Text>
                  )}
                </View>
              </View>
              <View style={styles.separator} />
              {!!descriptionPDF ? (
                <View style={styles.line}>
                  <View style={styles.verticalChunk}>
                    <Text style={styles.smallText}>Concepto</Text>
                    <Text>{descriptionPDF}</Text>
                  </View>
                </View>
              ) : null}
              {confirmedPayment.linkReference ? (
                <>
                  <View style={styles.separator} />
                  <View style={styles.line}>
                    <View style={styles.verticalChunk}>
                      <Text style={styles.smallText}>Referencia o Destinatario</Text>
                      <Text>{confirmedPayment.linkReference}</Text>
                    </View>
                  </View>
                </>
              ) : null}
            </View>
          </View>
          <View style={styles.footerContainer}>
            <Image style={styles.image} source={image} />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFReceipt;
