import { Paper, useMediaQuery } from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CustomSwitch } from '../../../components/CustomSwitch';
import { Loading } from '../../../components/Loading';
import { PaymentMethodCard } from '../../../components/PaymentMethodCard';
import schemas from '../../../data/schemas';
import { dateHelper } from '../../../helpers/dateHelper';
import { createLink, updateLink } from '../../../store/action_creators/links.actions';
import { Currency, LinkTypeEnum, PaymentTypeEnum, ValidUntil } from '../../../store/config/enums';
import {
  AuthState,
  CreateLinkRequest,
  Link,
  LinkValues,
  PaymentMethod,
  PaymentMethodsState,
  RootState,
  UpdateLinkRequest,
} from '../../../store/config/types';
import { theme } from '../../../styles/theme';
import Inputs from '../Inputs';
import LinkActions from '../LinkActions';

interface RegularLinkProps {
  clickedCopy: boolean | null;
  setClickedCopy: React.Dispatch<React.SetStateAction<boolean | null>>;
  creatingLink: boolean;
  setCreatingLink: React.Dispatch<React.SetStateAction<boolean>>;
  updatingLink: boolean;
  setUpdatingLink: React.Dispatch<React.SetStateAction<boolean>>;
  paymentMethodsIds: number[];
  setPaymentMethodsIds: React.Dispatch<React.SetStateAction<number[]>>;
  commonClasses: Record<string, string>;
}

function RegularLink({
  clickedCopy,
  setClickedCopy,
  creatingLink,
  setCreatingLink,
  updatingLink,
  setUpdatingLink,
  paymentMethodsIds,
  setPaymentMethodsIds,
  commonClasses,
}: RegularLinkProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const paymentMethods: PaymentMethodsState = useSelector((state: RootState) => state.paymentMethods);
  const auth: AuthState = useSelector((state: RootState) => state.auth);
  const linkType = location.pathname.includes('one-use') ? LinkTypeEnum.ONETIME : LinkTypeEnum.PERMANENT;
  const link = (location.state?.link as Link) ?? null;
  const allInstallmentIds = useMemo(
    () => paymentMethods.paymentMethods?.map((paymentMethod) => paymentMethod.id),
    [paymentMethods],
  );
  const businessDefaultInclusionLaw = auth.account?.business.defaultInclusionLaw || 0;

  useEffect(() => {
    if (!link && paymentMethods.paymentMethods) {
      setPaymentMethodsIds(paymentMethods.paymentMethods?.map((paymentMethod) => Number(paymentMethod.id)));
    }
  }, [paymentMethods, link, setPaymentMethodsIds]);

  const sendLinkRequest = (values: LinkValues) => {
    if (link) {
      setUpdatingLink(true);
      const request: UpdateLinkRequest = {
        vatRate: values.vatRate,
        amount: {
          value: values.value,
          currency: values.currency,
        },
        linkType: link.linkType,
        description: values.description !== undefined ? values.description : '',
        status: link.status,
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsIds: paymentMethodsIds,
        reference: values.reference || '',
        paymentType: PaymentTypeEnum.Regular,
        inclusionLaw: values.inclusionLaw,
      };

      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(updateLink(request, link.id, auth.account!.business.id!.toString()));
    } else {
      setCreatingLink(true);
      const request: CreateLinkRequest = {
        businessId: auth.account?.business.id !== undefined ? auth.account?.business.id! : 0,
        vatRate: values.vatRate,
        amount: {
          value: values.value,
          currency: values.currency,
        },
        linkType: linkType ? linkType : LinkTypeEnum.PERMANENT,
        userId: auth.account?.userId!,
        description: values.description !== undefined ? values.description : '',
        reference: values.reference || '',
        validUntil: dateHelper.getDateFromEnum(values.validUntil, values.validUntilDate),
        installmentsIds: paymentMethodsIds,
        paymentType: PaymentTypeEnum.Regular,
        inclusionLaw: values.inclusionLaw,
      };
      if (linkType === LinkTypeEnum.ONETIME) request.reference = values.reference;
      dispatch(createLink(request));
    }
  };

  const submitLink = (values: LinkValues, actions: FormikHelpers<LinkValues>) => {
    clickedCopy ? setClickedCopy(true) : setClickedCopy(false);

    if (paymentMethodsIds.length === 0) {
      actions.setSubmitting(false);
    } else {
      sendLinkRequest(values);
    }
  };

  const handleSwitchAllPaymentMethods = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethodsIds(event.target.checked ? allInstallmentIds || [] : []);
  };

  return (
    <>
      <Formik
        initialValues={{
          currency: link ? link.amount?.currency : Currency.PESO,
          vatRate: link ? link.vatRate : auth.account?.business.vatRate ? auth.account.business.vatRate : 0,
          value: link ? link.amount?.value : 0,
          description: link ? link.description : '',
          reference: link ? link.reference : '',
          validUntil: link && link.validUntil !== null ? ValidUntil.CUSTOM : ValidUntil.NONE,
          validUntilDate: link && link.validUntil ? new Date(link.validUntil) : new Date(),
          installmentsId: link ? link.commerceIds : [],
          inclusionLaw: link ? link.inclusionLaw : businessDefaultInclusionLaw,
        }}
        validationSchema={schemas.LinkSchema}
        onSubmit={(values, actions) => submitLink(values, actions)}
      >
        {({ values, setFieldValue }) => {
          return (
            <div className={commonClasses.container}>
              <Form id="new-link-form">
                <Paper className={commonClasses.paperRoot}>
                  <div className={commonClasses.titleContainer}>
                    <p className={commonClasses.contentTitle}>Información general</p>
                  </div>
                  <Inputs
                    values={values}
                    linkType={linkType}
                    paymentType={PaymentTypeEnum.Regular}
                    setFieldValue={setFieldValue}
                  />
                </Paper>
                <Paper className={commonClasses.paperRoot}>
                  <div className={commonClasses.paymentHeader}>
                    <p className={commonClasses.contentTitleHeader}>Medios de Pago</p>
                    {paymentMethods.paymentMethods && (
                      <div className={commonClasses.paymentSwitchContainer}>
                        <p>Todos habilitados</p>
                        <CustomSwitch
                          onChange={(event) => handleSwitchAllPaymentMethods(event)}
                          checked={paymentMethodsIds.length === paymentMethods.paymentMethods?.length}
                        />
                      </div>
                    )}
                  </div>
                  {!paymentMethods.paymentMethods ? (
                    <Loading />
                  ) : (
                    <>
                      <p>
                        Elige los medios de pago con los que deseas que puedan realizarse los pagos a través
                        de los links
                      </p>
                      {paymentMethods.paymentMethods && paymentMethodsIds.length === 0 && (
                        <p className={commonClasses.error}>Debes seleccionar al menos un medio de pago</p>
                      )}
                      <div className={commonClasses.paymentMethodContainer}>
                        {paymentMethods.paymentMethods?.map((paymentMethod: PaymentMethod) => {
                          return (
                            <PaymentMethodCard
                              key={paymentMethod.id}
                              paymentMethod={paymentMethod}
                              paymentMethodsIds={paymentMethodsIds}
                              setPaymentMethodsIds={setPaymentMethodsIds}
                            />
                          );
                        })}
                      </div>
                    </>
                  )}
                </Paper>
                {isMobile && (
                  <LinkActions
                    setClickedCopy={setClickedCopy}
                    link={link}
                    creatingLink={creatingLink}
                    updatingLink={updatingLink}
                    clickedCopy={clickedCopy}
                  />
                )}
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default RegularLink;
